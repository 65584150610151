import React, { useEffect, useState } from "react";
import "./SectionInput.css";
import { Accordion, Form } from "react-bootstrap";
import InputText from "./formelement/InputText";
import { useDispatch, useSelector } from "react-redux";
import { deleteSection, updateSection } from "../../REDUX/actions/section";
import i18n from "i18next";
import Uploader from "../Common/Uploader";
import Lists from "../Lists/Lists";
import _ from "lodash";
import CustomSelect from "./formelement/CustomSelect";
import CustomButton from "../Common/CustomButton";
import { openModal } from "../../REDUX/actions/confirmationModal";
import LoadingButton from "../Common/LoadingButton";

const SectionInput = ({
  titleOrange,
  titleBlack,
  sectionData = {},
  withSeo,
  sectionId,
  defaultExpand,
  shadowed = true,
  deleteButton,
  type,
  eventItemForm,
  noBtn,
  fetchProduct,
  image2LabelInput,
  forceShowImage = false,
}) => {
  const dispatch = useDispatch();
  const [image, setimage] = useState(sectionData.image?.data);
  const [image2, setimage2] = useState(sectionData.image2?.data);
  const [imageMobile, setImageMobile] = useState(sectionData.imageMobile?.data);
  const [imageMobile2, setImageMobile2] = useState(
    sectionData.imageMobile2?.data
  );
  const [buttonUrl, setbuttonUrl] = useState(sectionData.buttonUrl);
  const { sectionLoading } = useSelector((state) => state.pagesReducer);
  const [formData, setformData] = useState(
    _.omit(sectionData, [
      "image",
      "image2",
      "imageMobile",
      "imageMobile2",
      "createdAt",
      "updatedAt",
      "list",
      "locale",
      "article",
      "carousel",
      "event",
      "localizations",
      "page",
      "product",
      "revendeur",
      "resolution",
      "productjets",
      "articleBanner",
      "eventBanner",
    ])
  );
  useEffect(() => {
    setformData(
      _.omit(sectionData, [
        "image",
        "image2",
        "imageMobile",
        "imageMobile2",
        "createdAt",
        "updatedAt",
        "list",
        "locale",
        "article",
        "carousel",
        "event",
        "localizations",
        "page",
        "product",
        "revendeur",
        "resolution",
        "productjets",
        "articleBanner",
        "eventBanner",
      ])
    );
    setimage(sectionData.image?.data);
    setimage2(sectionData.image2?.data);
    setImageMobile(sectionData?.imageMobile?.data);
    setImageMobile2(sectionData?.imageMobile2?.data);
    setbuttonUrl(sectionData.buttonUrl);
    // eslint-disable-next-line
  }, [i18n.language, sectionData]);

  const handleSubmit = () => {
    dispatch(
      updateSection(
        {
          ...formData,
          buttonUrl: buttonUrl,
          image: image?.id,
          image2: image2 ? image2.id : null,
          imageMobile: imageMobile?.id,
          imageMobile2: imageMobile2?.id,
        },
        sectionId
      )
    );
  };
  const handleChange = (value, champs) => {
    setformData({ ...formData, [champs]: value });
  };

  const handleChangeButtonUrl = (value) => {
    setbuttonUrl(value);
  };

  const handleImage = (imageSelected) => {
    setimage(imageSelected);
  };
  const handleImage2 = (imageSelected) => {
    setimage2(imageSelected);
  };
  const handleImageMobile = (imageSelected) => {
    setImageMobile(imageSelected);
  };
  const handleImageMobile2 = (imageSelected) => {
    setImageMobile2(imageSelected);
  };
  const handleButtonShow = (value) => {
    setformData({ ...formData, showButton: value });
  };

  const handleDelete = () => {
    dispatch(deleteSection(sectionId, eventItemForm.id, type));
  };

  const showModalDelete = () => {
    dispatch(
      openModal({
        type: "delete",
        confirmButtonText: "Supprimer",
        cancelButton: true,
        message: "Êtes-vous sûr(e) de vouloir supprimer la section ?",
        callBack: handleDelete,
      })
    );
  };

  return (
    <Accordion
      className={`text-start rounded-10 ${shadowed ? "shadowed" : ""}`}
      defaultActiveKey={defaultExpand ? "0" : null}
    >
      <Accordion.Item
        className="card-body noBorder p-0 rounded-10"
        eventKey="0"
      >
        <Accordion.Header className="rounded-10">
          <h2 className="text-orange mb-0 f24 fw-bold">{titleOrange}</h2>
          <h2 className="mb-0 f24 fw-normal">{titleBlack}</h2>
        </Accordion.Header>

        <Accordion.Body>
          <div className="container-fluid">
            <div className="row my-5">
              {sectionData.regularTitle !== null ? (
                <div className="col-6">
                  <InputText
                    labelinput="Titre Régulier"
                    value={formData.regularTitle}
                    handleChange={handleChange}
                    champs="regularTitle"
                  />
                </div>
              ) : null}
              {sectionData.decoratedTitle !== null ? (
                <div className="col-6">
                  <InputText
                    labelinput="Titre Italique Bleu"
                    value={formData.decoratedTitle}
                    handleChange={handleChange}
                    champs="decoratedTitle"
                  />
                </div>
              ) : null}
            </div>
            <div className="row align-items-center">
              {(image || forceShowImage) && (
                <div className="col-6  d-flex flex-column align-items-center justify-content-center gap-5 mb-5">
                  <div className="row gap-3 w-100">
                    {(image || forceShowImage) && (
                      <div className="col mx-w-280">
                        <Uploader
                          handleImage={handleImage}
                          image={image}
                          resolution={sectionData.resolution}
                          targetScreen="desktop"
                          forceShow={forceShowImage}
                        />
                      </div>
                    )}
                    {image2 && (
                      <div className="col mx-w-280">
                        <Uploader
                          handleImage={handleImage2}
                          image={image2}
                          labelinput={image2LabelInput}
                          resolution={sectionData.resolution}
                          targetScreen="desktop 2"
                        />
                      </div>
                    )}

                    {(imageMobile || forceShowImage) && (
                      <div className="col mx-w-280">
                        <Uploader
                          handleImage={handleImageMobile}
                          image={imageMobile}
                          resolution={
                            sectionData.resolutionMobile || "(1080 x 1920)"
                          }
                          targetScreen="mobile "
                          forceShow={forceShowImage}
                        />
                      </div>
                    )}
                    {/* Uploader for imageMobile2 */}
                    {imageMobile2 && (
                      <div className="col mx-w-280">
                        <Uploader
                          handleImage={handleImageMobile2}
                          image={imageMobile2}
                          resolution={
                            sectionData.resolutionMobile || "(1080 x 1920)"
                          }
                          targetScreen="mobile"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div className="col ">
                {sectionData.title !== null ? (
                  <InputText
                    labelinput="Titre"
                    value={formData.title}
                    handleChange={handleChange}
                    champs="title"
                  />
                ) : null}
                {sectionData.subtitle !== null ? (
                  <InputText
                    labelinput="Sous-Titre"
                    value={formData.subtitle}
                    handleChange={handleChange}
                    champs="subtitle"
                  />
                ) : null}
                {!noBtn && (
                  <>
                    <div className="row mt-3 align-items-start">
                      <div className="col-2">
                        <label className="f16 ">Afficher Bouton </label>
                        <Form.Switch
                          key={Math.random()}
                          defaultChecked={formData.showButton}
                          onChange={(e) => handleButtonShow(e.target.checked)}
                        ></Form.Switch>
                      </div>

                      {formData.showButton && (
                        <div className="col">
                          <CustomSelect
                            handleChange={handleChangeButtonUrl}
                            value={buttonUrl}
                          />
                        </div>
                      )}
                    </div>
                    {formData.showButton && (
                      <div className="row pt-2 pb-2">
                        <div className="col">
                          <InputText
                            labelinput="Texte du bouton"
                            value={formData.buttonText}
                            handleChange={handleChange}
                            champs="buttonText"
                          />
                        </div>
                        <div className="col">
                          <InputText
                            labelinput="URL du bouton"
                            value={buttonUrl}
                            flag={false}
                            readOnly
                            champs="buttonUrl"
                          />
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {sectionData.description !== null ? (
                <div className="col-12">
                  <InputText
                    labelinput="Description"
                    textArea
                    value={formData.description}
                    handleChange={handleChange}
                    champs="description"
                  />
                </div>
              ) : null}
              {sectionData.content !== null || withSeo ? (
                <div className="col-12">
                  <InputText
                    labelinput={
                      withSeo ? "text SEO (affiché en bas de page)" : "Contenu"
                    }
                    textArea
                    value={formData.content}
                    handleChange={handleChange}
                    champs="content"
                  />
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col">
                <CustomButton
                  disabled={sectionLoading}
                  buttonText={
                    sectionLoading ? <LoadingButton /> : "Enregistrer"
                  }
                  callBack={handleSubmit}
                />
              </div>
              {deleteButton && (
                <div className="col-4">
                  <CustomButton
                    buttonText="Supprimer la section"
                    iconStart="trash-fill"
                    type="red"
                    callBack={showModalDelete}
                  />
                </div>
              )}
            </div>

            {sectionData.list?.data && (
              <Lists
                listData={sectionData.list.data}
                fetchProduct={fetchProduct}
              />
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default SectionInput;
