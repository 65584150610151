const initialState = {
  open: false,
  type: null,
  message: null,
  cancelButton: true,
  confirmButtonText: null,
  callBack: null,
  cancelCallback: null,
  component: null,
};

export const modalReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case "OPEN_MODAL":
      return {
        ...state,
        open: true,
        type: payload.type,
        message: payload.message,
        cancelButton: payload.cancelButton,
        confirmButtonText: payload.confirmButtonText,
        callBack: payload.callBack,
        cancelCallback: payload.cancelCallback,
        component: payload.component,
        disabled: payload.disabled,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        open: false,
        message: null,
        cancelButton: true,
        confirmButtonText: null,
        callBack: null,
        type: null,
        component: null,
      };
    default:
      return state;
  }
};
